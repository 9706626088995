/**
 * @tokens 90 colors — @solublestudio/soluto-starter-design-system
 * @presenter Color
 */
$primary: #92e735;
$primary-dark: #019494;
$primary-darker-opacity: rgba(40, 95, 122, 0.1);
$primary-darker: #285f7a;
$secondary: #ff495c;

$base-000: #111111;
$base-200: #c4c4c4;
$base-300: #e0e0e0;
$base-350: #ededed;
$base-400: #f3f3f3;
$base-1000: #ffffff;

$support-success: #407d40;
$support-error: #FF495C;
$danger: #c63532;
$support-alert: #ed8943;
